var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-4",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-text-field',{ref:"search",staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":_vm.$_strings.invoice.SEARCHNO},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
          _vm.filters.invoiceNo = _vm.search;
          _vm.$router.replace({
            query : Object.assign({}, _vm.$route.query,
              {search: _vm.search})
          })
        })($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":function () {
              _vm.filters.invoiceNo = _vm.search;
              _vm.$router.replace({
                query : Object.assign({}, _vm.$route.query,
                  {search: _vm.search})
              })
            }}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-sheet',{attrs:{"rounded":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('FilterInvoice',{attrs:{"filters":_vm.filters,"showFilterBy":true},on:{"fetchInvoice":_vm.fetchInvoice}})],1),(_vm.$route.params.companyType === 'shipper' && _vm.userAccess.canCreate)?_c('v-col',{staticClass:"ma-4 mr-6",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","elevation":"1","small":""},on:{"click":function($event){_vm.$refs.dialogCreateFixedPriceInvoice.dialog = true}}},[_vm._v(" "+_vm._s(_vm.$_strings.invoice.TITLE_CREATE)+" ")])],1):_vm._e()],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"calculate-widths":"","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.itemsInvoice,"item-key":"invoiceNo","server-items-length":_vm.invoiceListTotalEntry,"options":_vm.pagination,"footer-props":{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': _vm.$_item_per_page}},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.invoiceNo",fn:function(items){return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(items.item.invoiceNo))]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.dateFormat(items.item.invoiceDate)))])]}},{key:"item.invoiceGroup",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.renamePeriode(item.invoiceGroup)))]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.dateFormat(item.periodStart))+" s/d "+_vm._s(_vm.dateFormat(item.periodEnd)))])]}},{key:"item.lokasiAsal",fn:function(items){return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(items.item.lokasiAsal ? items.item.lokasiAsal : '-'))])]}},{key:"item.lokasiTujuan",fn:function(items){return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(items.item.lokasiTujuan ? items.item.lokasiTujuan : '-'))])]}},{key:"item.totalAmount",fn:function(items){return [_c('p',{staticClass:"ma-0"},[_vm._v("Rp. "+_vm._s(_vm.formatAmount(items.item.totalAmount)))]),(items.item.invoiceType === 'Pengiriman')?_c('span',{staticClass:"caption"},[_vm._v(_vm._s(items.item.totalShipment)+" Pesanan")]):_c('span',{staticClass:"caption"},[_vm._v(_vm._s(items.item.totalShipment)+" Biaya")])]}},{key:"item.invoiceType",fn:function(items){return [_c('span',[_vm._v(_vm._s(items.item.invoiceType))])]}},{key:"item.createdBy",fn:function(items){return [_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(items.item.createdBy))]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.dateFormat(items.item.createdAt)))])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [(item.isLoading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"size":"25","indeterminate":"","color":"primary"}})],1):_vm._e(),(_vm.$route.params.companyType === 'transporter')?_c('span',[_vm._v(_vm._s(item.status))]):_c('v-select',{staticClass:"mt-3 pa-0 text-caption",staticStyle:{"width":"10rem"},attrs:{"outlined":"","dense":"","items":_vm.itemsStatus(item),"item-value":"text","item-text":"text"},on:{"change":function ($event) { return _vm.changeStatus($event, item); },"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.actionColumn",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","x-small":"","color":"primary"},on:{"click":[function($event){return _vm.exportPdf(item)},function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true)},[_c('span',[_vm._v("File PDF")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.status === 'Proforma')?_c('div',_vm._g(_vm._b({staticClass:"text-center d-inline"},'div',attrs,false),on),[_c('v-menu',_vm._g(_vm._b({attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","x-small":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-excel-box")])],1)]}}],null,true)},'v-menu',attrs,false),on),[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.exportExcel(item)}}},[_c('v-list-item-title',[_vm._v("Invoice Proforma")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.exportExcel(item, 'detail')}}},[_c('v-list-item-title',[_vm._v("Invoice Proforma Detail")])],1)],1)],1)],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("File XLS")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(item.status === 'Proforma')?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"primary"},on:{"click":[function($event){return _vm.modalEmail(item)},function($event){$event.stopPropagation();$event.preventDefault();}]}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-email ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Email")])])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.invoice.INVOICE_LIST)+" "),(_vm.itemsInvoice.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1),_c('DialogCreateFixedPriceInvoice',{ref:"dialogCreateFixedPriceInvoice",on:{"fetchInvoice":_vm.fetchInvoice}}),_c('v-row',[_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.isLoadingSendEmail}},[_c('v-card-title',[_c('span',{staticClass:"headtitle-email"},[_vm._v("Masukan Email Tujuan")]),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","color":"black","dark":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-subheader',[_vm._v("Email")])],1),_c('v-col',{staticClass:"mr-4",attrs:{"cols":"10"}},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","items":_vm.getEmail,"search-input":_vm.searchEmail,"item-value":"email","item-text":"email","multiple":"","chips":"","persistent-hint":""},on:{"update:searchInput":function($event){_vm.searchEmail=$event},"update:search-input":function($event){_vm.searchEmail=$event}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var attrs = ref.attrs;
            var item = ref.item;
            var select = ref.select;
            var selected = ref.selected;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){return _vm.removeEmail(item)}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.email)),_c('br')])]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.email))])]}}]),model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mr-2 ml-auto",attrs:{"outlined":"","small":""},on:{"click":_vm.closeModal}},[_vm._v(" Batal ")]),_c('v-btn',{staticClass:"white--text",attrs:{"depressed":"","small":"","color":"primary","loading":_vm.isLoadingSendEmail},on:{"click":_vm.submitEmail}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)],1),_c('DialogDateReceived',{ref:"DialogDateReceived",attrs:{"invoiceDate":_vm.periodStart},on:{"updateStatusInvoice":_vm.updateStatusInvoice,"fetchInvoice":_vm.fetchInvoice}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }