<template>
  <v-container fluid>
    <v-row justify="end" class="mb-4">
      <v-col
        cols="12"
        md="3"
        sm="6"
      >
        <v-text-field
          dense
          hide-details
          outlined
          class="caption"
          v-model="search"
          :placeholder="$_strings.invoice.SEARCHNO"
          ref="search"
          @keyup.enter="() => {
            filters.invoiceNo = search;
            $router.replace({
              query : {
                ...$route.query,
                search
              }
            })
          }"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="
              () => {
                filters.invoiceNo = search;
                $router.replace({
                  query : {
                    ...$route.query,
                    search
                  }
                })
              }
            ">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-sheet rounded>
      <v-row>
        <v-col class="pa-0">
          <FilterInvoice
            :filters="filters"
            :showFilterBy="true"
            @fetchInvoice="fetchInvoice"
          />
        </v-col>
        <v-col
          cols="auto"
          class="ma-4 mr-6"
          v-if="$route.params.companyType === 'shipper' && userAccess.canCreate"
        >
          <v-btn
            color="primary"
            elevation="1"
            small
            @click="$refs.dialogCreateFixedPriceInvoice.dialog = true"
          >
            {{$_strings.invoice.TITLE_CREATE}}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            calculate-widths
            :loading="isLoading"
            :headers="headers"
            :items="itemsInvoice"
            item-key="invoiceNo"
            :server-items-length="invoiceListTotalEntry"
            :options.sync="pagination"
            @click:row="rowClicked"
            :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
          >
            <template v-slot:[`item.invoiceNo`]=items>
              <p class="ma-0">{{ items.item.invoiceNo }}</p>
              <span class="caption">{{ dateFormat(items.item.invoiceDate) }}</span>
            </template>
            <template v-slot:[`item.invoiceGroup`]={item}>
              <p class="ma-0">{{ renamePeriode(item.invoiceGroup) }}</p>
              <span class="caption">{{ dateFormat(item.periodStart) }} s/d {{ dateFormat(item.periodEnd) }}</span>
            </template>
            <template v-slot:[`item.lokasiAsal`]=items>
              <p class="ma-0">{{ items.item.lokasiAsal ? items.item.lokasiAsal : '-' }}</p>
            </template>
            <template v-slot:[`item.lokasiTujuan`]=items>
              <p class="ma-0">{{ items.item.lokasiTujuan ? items.item.lokasiTujuan : '-' }}</p>
            </template>
            <template v-slot:[`item.totalAmount`]=items>
              <p class="ma-0">Rp. {{ formatAmount(items.item.totalAmount) }}</p>
              <span class="caption" v-if="items.item.invoiceType === 'Pengiriman'">{{ items.item.totalShipment }} Pesanan</span>
              <span class="caption" v-else>{{ items.item.totalShipment }} Biaya</span>
            </template>
            <template v-slot:[`item.invoiceType`]=items>
              <span>{{ items.item.invoiceType }}</span>
            </template>
            <template v-slot:[`item.createdBy`]=items>
              <p class="ma-0">{{ items.item.createdBy }}</p>
              <span class="caption">{{ dateFormat(items.item.createdAt) }}</span>
            </template>
            <template v-slot:[`item.status`]="{item}">
              <div v-if="item.isLoading" class="d-flex justify-center">
                <v-progress-circular
                  size="25"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <span v-if="$route.params.companyType === 'transporter'">{{ item.status }}</span>
              <v-select
                v-else
                class="mt-3 pa-0 text-caption"
                style="width: 10rem"
                outlined
                dense
                :items="itemsStatus(item)"
                item-value="text"
                item-text="text"
                v-model="item.status"
                @change="($event) => changeStatus($event, item)"
                @click.stop.prevent
              >
              </v-select>
            </template>
            <template v-slot:[`item.actionColumn`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    x-small
                    class="mr-2"
                    color="primary"
                    @click="exportPdf(item)"
                    @click.stop.prevent
                  >
                    <v-icon>mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>File PDF</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-if="item.status === 'Proforma'" v-bind="attrs" v-on="on" class="text-center d-inline">
                    <v-menu v-bind="attrs" v-on="on" offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          x-small
                          class="mr-2"
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-file-excel-box</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item  @click="exportExcel(item)">
                          <v-list-item-title>Invoice Proforma</v-list-item-title>
                        </v-list-item>
                        <v-list-item  @click="exportExcel(item, 'detail')">
                          <v-list-item-title>Invoice Proforma Detail</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </div>
                </template>
                <span>File XLS</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="item.status === 'Proforma'"  v-bind="attrs" v-on="on" x-small icon class="mr-2" color="primary" @click="modalEmail(item)" @click.stop.prevent >
                    <v-icon> mdi-email </v-icon>
                  </v-btn>
                </template>
                <span>Email</span>
              </v-tooltip>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.invoice.INVOICE_LIST}}
                <span v-if="itemsInvoice.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <DialogCreateFixedPriceInvoice
      ref="dialogCreateFixedPriceInvoice"
      @fetchInvoice="fetchInvoice"
    />
    <!-- Button Dialog Email -->
    <v-row>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="700"
      >
        <v-card :loading="isLoadingSendEmail">
          <v-card-title>
            <span class="headtitle-email">Masukan Email Tujuan</span>
            <v-row>
              <v-col class="d-flex justify-end">
                <v-toolbar-items>
                  <v-btn
                    icon
                    color="black"
                    dark
                    @click="closeModal"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-col>
            </v-row>
          </v-card-title>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
              <v-row>
                <v-col>
                  <v-subheader>Email</v-subheader>
                </v-col>
                <v-col
                  cols="10"
                  class="mr-4"
                >
                  <v-autocomplete
                    outlined
                    dense
                    :items="getEmail"
                    :search-input.sync="searchEmail"
                    item-value="email"
                    item-text="email"
                    v-model="form.email"
                    multiple
                    chips
                    persistent-hint
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeEmail(item)"
                      >
                        {{ item.email }}<br>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <span>{{item.email}}</span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  outlined
                  small
                  class="mr-2 ml-auto"
                  @click="closeModal"
                >
                  Batal
                </v-btn>
                <v-btn
                  depressed
                  small
                  color="primary"
                  class="white--text"
                  @click="submitEmail"
                  :loading="isLoadingSendEmail"
                >
                  Simpan
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
    <DialogDateReceived
      ref="DialogDateReceived"
      @updateStatusInvoice="updateStatusInvoice"
      @fetchInvoice="fetchInvoice"
      :invoiceDate="periodStart"
    />
  </v-container>
</template>

<script>
import {
  skipEmptyStringObject,
  thousandSeparated,
  renamePeriode,
  dateFormat,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import dayjs from 'dayjs';
import FilterInvoice from './FilterInvoice.vue';
import DialogCreateFixedPriceInvoice from './Dialog/CreateFixedPriceInvoice.vue';
import DialogDateReceived from '../InvoiceShipper/Dialog/DateReceived.vue';

export default {
  name: 'InvoiceList',
  components: {
    FilterInvoice,
    DialogCreateFixedPriceInvoice,
    DialogDateReceived,
  },
  data() {
    return {
      isLoadingSendEmail: false,
      isLoading: false,
      headers: [
        {
          text: this.$_strings.invoice.INVOICE_NO,
          value: 'invoiceNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.SHIPPER_NAME,
          value: 'billedToCompanyName',
          class: 'white--text primary text-capitalize',
          width: '180px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.ORDER_PERIODE,
          value: 'invoiceGroup',
          class: 'white--text primary text-capitalize',
          width: '200px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.order.ORIGIN,
          value: 'lokasiAsal',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.order.DESTINATION,
          value: 'lokasiTujuan',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.invoice.INVOICE_TOTAL,
          value: 'totalAmount',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.INVOICE_TYPE,
          value: 'invoiceType',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.CREATED_BY,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
          width: '180px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.STATUS,
          value: 'status',
          class: 'white--text primary text-capitalize',
          width: '150px',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.invoice.ACTION,
          value: 'actionColumn',
          align: 'left',
          class: 'white--text primary text-capitalize',
          sortable: false,
          width: '10vw',
        },
      ],
      itemsInvoice: [],
      search: this.$route.query.search || '',
      filters: {
        invoiceNo: this.$route.query.search || '',
        dateFrom: this.$route.query.dateFrom || dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
        dateTo: this.$route.query.dateTo || dayjs().format('YYYY-MM-DD'),
        statusInvoice: this.$route.query.statusInvoice ? this.$route.query.statusInvoice.split(',') : [],
        typeInvoice: this.$route.query.typeInvoice ? this.$route.query.typeInvoice.split(',') : [],
        billedToCompanyId: Number(this.$route.query.billedToCompanyId) || '',
      },
      invoiceListTotalEntry: 0,
      pagination: defaultPagination(),
      statusChange: '',
      valid: false,
      form: {
        email: [],
      },
      dialog: false,
      getEmail: [],
      searchEmail: null,
      invoiceId: '',
      invoiceStatusId: '',
      companyId: '',
      periodStart: '',
    };
  },
  computed: {
    companyTypePath() {
      const [,, companyTypePath] = this.$route.path.split('/');
      return companyTypePath;
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchInvoice();
      },
      deep: true,
    },
    'filters.dateFrom': function setQueryDateFrom(newVal) {
      this.$router.replace({
        query: {
          dateFrom: newVal,
          dateTo: this.filters.dateTo,
        },
      });
    },
    'filters.dateTo': function setQueryDateFrom(newVal) {
      this.$router.replace({
        query: {
          dateFrom: this.filters.dateFrom,
          dateTo: newVal,
        },
      });
    },
    companyTypePath() {
      this.filters.invoiceNo = '';
      this.filters.dateFrom = dayjs().subtract(30, 'days').format('YYYY-MM-DD');
      this.filters.dateTo = dayjs().format('YYYY-MM-DD');
      this.filters.statusInvoice = [];
      this.filters.typeInvoice = [];
      this.filters.billedToCompanyId = '';
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: '',
          statusInvoice: '',
          typeInvoice: '',
          billedToCompanyId: '',
        },
      });
      this.pagination = {
        ...this.pagination,
        page: 1,
        itemsPerPage: 10,
      };
    },
    searchEmail(val) {
      if (val === null) {
        this.$_services.invoice.emailList(this.companyId)
          .then((res) => {
            this.getEmail = res.data.contents;
          });
      } else {
        this.$_services.invoice.emailSearchList(val, this.companyId)
          .then((res) => {
            this.getEmail = res.data.contents;
          });
      }
    },
  },
  methods: {
    rowClicked(item) {
      const { companyType } = this.$route.params;
      // if (item.invoiceType === 'Pengiriman') {
      //   this.$router.push({
      //     name: companyType === 'shipper' ? 'invoice-list-detail-customer-fixed-price' : 'invoice-list-detail-transporter-fixed-price',
      //     params: {
      //       invoiceId: item.id,
      //       companyType: this.$route.params.companyType,
      //     },
      //   });
      // } else if (item.status === 'Proforma' && companyType === 'shipper') {
      //   const params = {
      //     invoiceId: item.id,
      //   };
      //   this.$router.push({
      //     name: 'invoice-edit',
      //     params,
      //   });
      // }
      this.$router.push({
        name: companyType === 'shipper' ? 'invoice-list-detail-customer-fixed-price' : 'invoice-list-detail-transporter-fixed-price',
        params: {
          invoiceId: item.id,
          companyType: this.$route.params.companyType,
        },
      });
    },
    async fetchInvoice() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: handleSortBy({ sortBy, sortDesc }),
      };
      Object.keys(this.filters).forEach((filter) => {
        if (typeof this.filters[filter] === 'object'
        && this.filters[filter] !== null
        && this.filters[filter].length) {
          filters[filter] = `qin(${this.filters[filter].toString()})`;
          return;
        }
        if (this.filters[filter] && filter === 'invoiceNo') {
          filters[filter] = `qlike(${this.filters[filter]})`;
          return;
        }
        if (this.filters[filter]) {
          filters[filter] = this.filters[filter];
        }
      });
      try {
        const { companyType } = this.$route.params;
        this.isLoading = true;
        const result = await this.$_services.invoice.listFixedPriceInvoice(skipEmptyStringObject(filters), companyType);
        this.itemsInvoice = result.data.contents;
        this.invoiceListTotalEntry = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    changeStatus(event, item) {
      this.periodStart = dayjs(item.periodStart).format('YYYY-MM-DD');
      this.statusChange = this.$_strings.invoice.FILTER_STATUS_LIST.find((i) => i.text === event).value;
      this.invoiceStatusId = item.id;
      if (event === 'Sudah diterima' || event === 'Sudah dibayar') {
        this.$refs.DialogDateReceived.minDate = dayjs(item.createdAt).format();
        this.$refs.DialogDateReceived.dialog = true;
        return;
      }
      this.updateStatusInvoice();
    },
    updateStatusInvoice(date = '') {
      this.$root.$loading.show();
      this.$_services.invoice.changeStatusInvoice(this.invoiceStatusId, this.statusChange, date)
        .then((res) => {
          this.$dialog.notify.success(this.$_strings.invoice.EDIT_STATUS_MESSAGE_TEXT);
        })
        .catch((err) => {
          if (err.data && err.data.message) {
            this.$dialog.notify.error(err.data.message);
          }
        })
        .finally(() => {
          this.$root.$loading.hide();
          this.fetchInvoice();
        });
    },
    itemsStatus(item) {
      let statusItems = this.$_strings.invoice.FILTER_STATUS_LIST;
      if (!item.isIntegratedOracle) statusItems = this.$_strings.invoice.FILTER_STATUS_LIST.filter((i) => i.value !== 'SEND_ORACLE' && i.value !== 'HALF_PAID');
      const findIndex = statusItems.findIndex((e) => e.text === item.status);
      const itemList = [];
      statusItems.forEach((i, index) => {
        if ((index === findIndex || index === findIndex + 1) && i.value !== 'HALF_PAID') {
          itemList.push({
            ...i,
          });
          return;
        }
        if (statusItems[findIndex + 1] && statusItems[findIndex + 1].value === 'HALF_PAID' && index === findIndex + 2) {
          itemList.push({
            ...i,
            disabled: false,
          });
          return;
        }
        itemList.push({
          ...i,
          disabled: true,
        });
      });
      return itemList;
    },
    renamePeriode,
    dateFormat,
    formatAmount(amount) {
      return thousandSeparated(amount);
    },
    removeEmail(item) {
      this.form.email.splice(this.form.email.indexOf(item), 1);
      this.form.email = [...this.form.email];
    },
    getEmailList() {
      this.$_services.invoice.emailList(this.companyId)
        .then((res) => {
          this.getEmail = res.data.contents;
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
    modalEmail(item) {
      this.dialog = true;
      this.invoiceId = item.id;
      this.companyId = item.billedToCompanyId;
      this.getEmailList();
    },
    closeModal() {
      this.dialog = false;
      this.invoiceId = '';
      this.companyId = '';
    },
    exportPdf(item) {
      if (item.invoiceNo) {
        this.$_services.invoice.exportPdfInvoice(item.id).then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          const { invoiceNo } = item;
          const invoiceLink = invoiceNo.replace(/\//g, '_');
          link.download = `invoice-${invoiceLink}.pdf`;
          link.click();
          URL.revokeObjectURL(link.href);
        }).catch(console.error);
        return;
      }
      const parameter = {
        invoiceId: item.id,
        invoiceType: 'MANAGEMENT',
        exportFile: 'PDF',
      };
      this.$_services.invoice.exportPdfInvoiceProforma(parameter).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'invoice-proforma.pdf';
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    exportExcel(item, type) {
      const parameter = {
        invoiceId: item.id,
        invoiceType: 'MANAGEMENT',
        exportFile: 'EXCEL',
      };
      let service = this.$_services.invoice.exportPdfInvoiceProforma;
      let nameFile = 'invoice-proforma.xls';
      if (type === 'detail') {
        service = this.$_services.invoice.exportInvoiceProformaDetail;
        nameFile = 'invoice-proforma-detail.xls';
      }
      service(parameter).then((response) => {
        const blob = new Blob([response.data], { type: 'application/xls' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = nameFile;
        link.click();
        URL.revokeObjectURL(link.href);
      }).catch(console.error);
    },
    submitEmail() {
      if (this.form.email.length < 1) {
        this.$dialog.notify.error('Mohon periksa kembali field yang kosong');
        return;
      }
      this.isLoadingSendEmail = true;
      this.$_services.invoice.sendEmail(this.form.email, this.invoiceId)
        .then(() => {
          this.$dialog.notify.success('Berhasil mengirimkan proforma ke email.');
        }).finally(() => {
          this.isLoadingSendEmail = false;
          this.dialog = false;
          this.$refs.form.reset();
          this.fetchInvoice();
        });
    },
  },
};
</script>
