<template>
  <v-container fluid>
    <v-row>
      <v-col cols="auto">
        <v-row class="pl-5">
          <v-col>
            <span>{{ $_strings.invoice.DATE }}</span>
            <v-menu
              ref="menuFromDate"
              v-model="menuFromDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details
                  outlined
                  class="caption"
                  placeholder="From Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  :value="dayjs(filters.dateFrom).format('DD-MM-YYYY')"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.dateFrom"
                no-title
                :max="filters.dateTo"
                @change="menuFromDate = false"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="auto pa-0 ma-0 d-flex align-center mt-6">-</v-col>
          <v-col>
            <v-menu
              ref="menuToDate"
              v-model="menuToDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  hide-details
                  class="caption mt-6"
                  outlined
                  :value="dayjs(filters.dateTo).format('DD-MM-YYYY')"
                  placeholder="To Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :min="filters.dateFrom"
                v-model="filters.dateTo"
                no-title
                @change="menuToDate = false"
                locale="id"
              >
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="showFilterBy" cols="auto">
        <span>Filter</span>
        <v-menu
          bottom
          :close-on-content-click="closeOnContentClick"
          width="300"
          offset-y
          z-index="1"
          allow-overflow
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              height="10"
              class="caption"
              prepend-inner-icon="mdi-filter"
              :placeholder="$_strings.invoice.FILTER"
              outlined
              dense
              readonly
              v-bind="attrs"
              @click="closeOnContentClick = false"
              v-on="on"
            ></v-text-field>
          </template>
          <v-list
            width="300"
            min-height="150"
            max-height="400"
            class="overflow-auto"
          >
            <section class="mb-2" v-for="(filterItem, index) in filterItems" :key="index">
              <v-list-item-title class="caption ml-4 font-weight-bold">{{filterItem.title}}</v-list-item-title>
              <v-list-item>
                <v-list-item-content class="pt-0 mt-0">
                  <section
                    v-for="chip in filters[filterItem.vModel]"
                    :key="chip"
                    class="mb-2"
                  >
                    <v-chip
                      close
                      small
                      @click:close="filters[filterItem.vModel].splice(filters[filterItem.vModel].indexOf(chip), 1)"
                    >
                      <strong>{{ filterItem.items.find((fi) => fi.value === chip).text }}</strong>
                    </v-chip>
                  </section>
                  <v-select
                    hide-details
                    flat
                    :items="filterItem.items.filter((item) => !(filters[filterItem.vModel] && filters[filterItem.vModel].includes(item.value)))"
                    :placeholder="filterItem.placeholder"
                    :menu-props="{ bottom: true, offsetY: true }"
                    class="caption"
                    dense
                    v-model="filters[filterItem.vModel]"
                    multiple
                    chips
                  >
                    <template v-slot:item="{ item }">
                      <span class="caption">{{item.text}}</span>
                    </template>
                  </v-select>
                </v-list-item-content>
              </v-list-item>
            </section>
            <section>
              <v-list-item-title class="caption ml-4 font-weight-bold">
                {{$route.params.companyType === 'transporter' ? $_strings.invoice.TRANSPORTER : $_strings.invoice.SHIPPER}}
              </v-list-item-title>
              <v-list-item>
                <v-list-item-content class="pt-0 mt-0">
                  <v-autocomplete
                    hide-details
                    flat
                    dense
                    :items="itemsCompany"
                    class="caption"
                    :placeholder="$route.params.companyType === 'transporter' ? 'Semua Transporter' : 'Semua Shipper'"
                    :loading="isLoadingCompanyList"
                    @input.native="searchCompanyList"
                    v-model="filters.billedToCompanyId"
                    item-value="id"
                    item-text="companyName"
                    clearable
                  >
                  </v-autocomplete>
                </v-list-item-content>
              </v-list-item>
            </section>
            <section class="pl-2 pr-2">
              <v-btn
                depressed
                class="mt-2 white--text"
                color="red"
                block
                @click="closeOnContentClick = true"
              >
                {{$_strings.common.CLOSE}}
              </v-btn>
            </section>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';

let search;

export default {
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    showFilterBy: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    filters: {
      handler() {
        this.$emit('fetchInvoice');
      },
      deep: true,
    },
    'filters.statusInvoice': function setQueryStringStatusInvoice(newVal) {
      const { statusInvoice } = this.$route.query;
      if (statusInvoice !== newVal.toString()) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            statusInvoice: newVal.toString(),
          },
        });
      }
    },
    'filters.typeInvoice': function setQueryStringTypeInvoice(newVal) {
      const { typeInvoice } = this.$route.query;
      if (typeInvoice !== newVal.toString()) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            typeInvoice: newVal.toString(),
          },
        });
      }
    },
    'filters.billedToCompanyId': function setQueryStringBilledToCompanyId(newVal) {
      const { billedToCompanyId } = this.$route.query;
      if (billedToCompanyId !== newVal) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            billedToCompanyId: newVal,
            companyName: newVal && this.itemsCompany.find((comp) => comp.id === newVal).companyName,
          },
        });
      }
    },
  },
  mounted() {
    if (this.showFilterBy) {
      this.fetchCompanyList();
    }
  },
  data() {
    return {
      isLoadingCompanyList: false,
      itemsCompany: [],
      menuFromDate: false,
      menuToDate: false,
      filterItems: [
        {
          title: this.$_strings.invoice.FILTER_STATUS,
          placeholder: this.$_strings.invoice.CHOOSE_STATUS,
          vModel: 'statusInvoice',
          items: this.$_strings.invoice.FILTER_STATUS_LIST,
        },
        {
          title: this.$_strings.invoice.FILTER_TYPE,
          placeholder: this.$_strings.invoice.CHOOSE_TYPE,
          vModel: 'typeInvoice',
          items: this.$_strings.invoice.FILTER_TYPE_LIST,
        },
      ],
      closeOnContentClick: false,
    };
  },
  methods: {
    dayjs,
    searchCompanyList(event) {
      window.clearInterval(search);

      search = setTimeout(() => {
        this.fetchCompanyList(event);
      }, 500);
    },
    async fetchCompanyList(event) {
      const { billedToCompanyId, companyName } = this.$route.query;
      let byCompanyName = '';
      if (event && event.target && event.target.value) {
        byCompanyName = event.target.value;
      }
      const { companyType } = this.$route.params;
      try {
        this.isLoadingCompanyList = true;

        const service = companyType === 'transporter' ? this.$_services.invoice.companySearch : this.$_services.invoice.companySearchShipper;
        const result = await service(byCompanyName);
        this.itemsCompany = [...this.itemsCompany, ...result.data.contents];
        if (billedToCompanyId && !(this.itemsCompany.find((comp) => Number(comp.id) === Number(billedToCompanyId)))) {
          this.itemsCompany.push({
            id: billedToCompanyId,
            companyName,
          });
        }
      } finally {
        this.isLoadingCompanyList = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  p.p_ellipsis {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
