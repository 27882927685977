var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{staticClass:"pl-5"},[_c('v-col',[_c('span',[_vm._v(_vm._s(_vm.$_strings.invoice.DATE))]),_c('v-menu',{ref:"menuFromDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"From Date","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.dayjs(_vm.filters.dateFrom).format('DD-MM-YYYY')}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFromDate),callback:function ($$v) {_vm.menuFromDate=$$v},expression:"menuFromDate"}},[_c('v-date-picker',{attrs:{"no-title":"","max":_vm.filters.dateTo,"locale":"id"},on:{"change":function($event){_vm.menuFromDate = false}},model:{value:(_vm.filters.dateFrom),callback:function ($$v) {_vm.$set(_vm.filters, "dateFrom", $$v)},expression:"filters.dateFrom"}})],1)],1),_c('v-col',{attrs:{"cols":"auto pa-0 ma-0 d-flex align-center mt-6"}},[_vm._v("-")]),_c('v-col',[_c('v-menu',{ref:"menuToDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption mt-6",attrs:{"dense":"","hide-details":"","outlined":"","value":_vm.dayjs(_vm.filters.dateTo).format('DD-MM-YYYY'),"placeholder":"To Date","prepend-inner-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuToDate),callback:function ($$v) {_vm.menuToDate=$$v},expression:"menuToDate"}},[_c('v-date-picker',{attrs:{"min":_vm.filters.dateFrom,"no-title":"","locale":"id"},on:{"change":function($event){_vm.menuToDate = false}},model:{value:(_vm.filters.dateTo),callback:function ($$v) {_vm.$set(_vm.filters, "dateTo", $$v)},expression:"filters.dateTo"}})],1)],1)],1)],1),(_vm.showFilterBy)?_c('v-col',{attrs:{"cols":"auto"}},[_c('span',[_vm._v("Filter")]),_c('v-menu',{attrs:{"bottom":"","close-on-content-click":_vm.closeOnContentClick,"width":"300","offset-y":"","z-index":"1","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"caption",attrs:{"hide-details":"","height":"10","prepend-inner-icon":"mdi-filter","placeholder":_vm.$_strings.invoice.FILTER,"outlined":"","dense":"","readonly":""},on:{"click":function($event){_vm.closeOnContentClick = false}}},'v-text-field',attrs,false),on))]}}],null,false,929107464)},[_c('v-list',{staticClass:"overflow-auto",attrs:{"width":"300","min-height":"150","max-height":"400"}},[_vm._l((_vm.filterItems),function(filterItem,index){return _c('section',{key:index,staticClass:"mb-2"},[_c('v-list-item-title',{staticClass:"caption ml-4 font-weight-bold"},[_vm._v(_vm._s(filterItem.title))]),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pt-0 mt-0"},[_vm._l((_vm.filters[filterItem.vModel]),function(chip){return _c('section',{key:chip,staticClass:"mb-2"},[_c('v-chip',{attrs:{"close":"","small":""},on:{"click:close":function($event){_vm.filters[filterItem.vModel].splice(_vm.filters[filterItem.vModel].indexOf(chip), 1)}}},[_c('strong',[_vm._v(_vm._s(filterItem.items.find(function (fi) { return fi.value === chip; }).text))])])],1)}),_c('v-select',{staticClass:"caption",attrs:{"hide-details":"","flat":"","items":filterItem.items.filter(function (item) { return !(_vm.filters[filterItem.vModel] && _vm.filters[filterItem.vModel].includes(item.value)); }),"placeholder":filterItem.placeholder,"menu-props":{ bottom: true, offsetY: true },"dense":"","multiple":"","chips":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.text))])]}}],null,true),model:{value:(_vm.filters[filterItem.vModel]),callback:function ($$v) {_vm.$set(_vm.filters, filterItem.vModel, $$v)},expression:"filters[filterItem.vModel]"}})],2)],1)],1)}),_c('section',[_c('v-list-item-title',{staticClass:"caption ml-4 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$route.params.companyType === 'transporter' ? _vm.$_strings.invoice.TRANSPORTER : _vm.$_strings.invoice.SHIPPER)+" ")]),_c('v-list-item',[_c('v-list-item-content',{staticClass:"pt-0 mt-0"},[_c('v-autocomplete',{staticClass:"caption",attrs:{"hide-details":"","flat":"","dense":"","items":_vm.itemsCompany,"placeholder":_vm.$route.params.companyType === 'transporter' ? 'Semua Transporter' : 'Semua Shipper',"loading":_vm.isLoadingCompanyList,"item-value":"id","item-text":"companyName","clearable":""},nativeOn:{"input":function($event){return _vm.searchCompanyList($event)}},model:{value:(_vm.filters.billedToCompanyId),callback:function ($$v) {_vm.$set(_vm.filters, "billedToCompanyId", $$v)},expression:"filters.billedToCompanyId"}})],1)],1)],1),_c('section',{staticClass:"pl-2 pr-2"},[_c('v-btn',{staticClass:"mt-2 white--text",attrs:{"depressed":"","color":"red","block":""},on:{"click":function($event){_vm.closeOnContentClick = true}}},[_vm._v(" "+_vm._s(_vm.$_strings.common.CLOSE)+" ")])],1)],2)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }